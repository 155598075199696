<template>
  <div class="visit-temporary-exist-edit">
    <div class="card">
      <van-row class="title">
        <van-col span="24">
          {{ data.customerName }}
        </van-col>
      </van-row>
      <van-row class="data">
        <van-col span="12">
          授信等级：{{ data.customerLevels }}
        </van-col>
        <van-col span="12">
          客户类型：{{ data.enterpriseType }}
        </van-col>
      </van-row>
      <van-row class="data">
        <van-col span="12">
          职务 ：{{ data.contactsPost }}
        </van-col>
        <van-col span="12">
          姓名 :{{ data.contacts }}
        </van-col>
      </van-row>
      <van-row class="data">
        电话 :{{ data.contactsPhone }}
      </van-row>
      <van-row class="data" @click="showMap">
        地址：{{ data.addressDetailed }}
      </van-row>
      <van-row class="data" @click="showPopup">
        <van-col span="22">
          更多地址/联系人：
        </van-col>
        <van-col span="2">
          <i class="el-icon-right"></i>
        </van-col>
      </van-row>
    </div>
    <div>
      <van-form @submit="addPlan()">
        <van-field readonly label="走访名称：" placeholder="走访名称"
                   v-model="data.titleName"></van-field>
        <van-field readonly label="客户：" placeholder="客户"
                   v-model="data.customerName"></van-field>
        <van-field
            readonly
            clickable
            label="企业性质："
            :value="data.enterpriseType"
            placeholder="请选择企业性质"
            input-align="left"
            @click="onClickEnterpriseNature()"
        />
        <van-popup v-model="showEnterpriseNaturePicker" round position="bottom">
          <van-picker
              show-toolbar
              :columns="columnsEnterpriseNature"
              @cancel="showEnterpriseNaturePicker = false"
              @confirm="onConfirmEnterpriseNature"
              default-index="0"
          />
        </van-popup>
        <van-field
            readonly
            clickable
            label="拜访类型："
            :value="data.travelType"
            placeholder="请选择拜访类型"
            input-align="left"
            @click="onClickVisitType()"
        />
        <van-popup v-model="showVisitTypePicker" round position="bottom">
          <van-picker
              show-toolbar
              :columns="columnsVisitType"
              @cancel="showVisitTypePicker = false"
              @confirm="onConfirmVisitType"
              default-index="0"
          />
        </van-popup>
        <van-field
            readonly
            clickable
            name="datetimePicker"
            :value="data.travelDate"
            label="计划日期："
            placeholder="点击选择时间"
            @click="showPicker = false"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker
              type="date"
              :maxDate="maxDate"
              v-model="currentDate"
              @confirm="onConfirm"
              @cancel="showPicker = false"
          />
        </van-popup>
        <van-field v-if="data.target" readonly type="textarea" label="目标：" :rules="customerForm.target" placeholder="目标"
                   v-model="data.target"></van-field>
        <van-field v-if="data.situation" readonly type="textarea" label="现状：" :rules="customerForm.situation"
                   placeholder="现状"
                   v-model="data.situation"></van-field>
        <van-field v-if="data.plan" readonly type="textarea" label="计划方案：" :rules="customerForm.plan" placeholder="计划方案"
                   v-model="data.plan"></van-field>
        <van-row v-if="data.interviewType">
          <van-field
              readonly
              clickable
              label="走访类型："
              input-align="left"
              :value="data.interviewType"
              :rules="customerForm.interviewType"
              placeholder="选择走访类型"
              @click="onClickType"
          />
          <van-popup v-model="showInterviewTypePicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="interviewType"
                @cancel="showInterviewTypePicker = false"
                @confirm="onConfirmType"
                default-index="0"
            />
          </van-popup>
        </van-row>
        <van-field v-if="data.goalPlan" autosize type="textarea" label="目标及方案" placeholder="目标及方案"
                   :rules="customerForm.goalPlan"
                   v-model="data.goalPlan === 'null'?'':data.goalPlan"></van-field>
        <van-field v-if="data.realContact" label="实际联系人：" :rules="customerForm.realContact" placeholder="实际联系人"
                   v-model="data.realContact === 'null'?'':data.realContact"></van-field>
        <van-field v-if="data.pushResult" readonly label="业务推进成果" :rules="customerForm.pushResult"
                   placeholder="请填写业务推进成果"
                   v-model="data.pushResult === 'null'?'':data.pushResult"></van-field>
        <van-field v-if="data.comOperate" readonly label="综合经营情况" :rules="customerForm.comOperate"
                   placeholder="业务量、应收情况、人员等方面"
                   v-model="data.comOperate === 'null'?'':data.comOperate"></van-field>
        <van-field v-if="data.businessVolume||data.profitSituation||data.others" label="综合经营情况"
                   :readonly="true" label-width="6.5em"></van-field>
        <van-field v-if="data.businessVolume" required label="业务量" placeholder="业务量" :rules="customerForm.businessVolume"
                   @click-input="clickItem(data.businessVolume, '业务量', false)"
                   v-model="data.businessVolume === 'null'?'':data.businessVolume" label-width="6.5em"
                   class="lable"></van-field>
        <van-field v-if="data.profitSituation" required label="盈利情况" placeholder="应收情况" :rules="customerForm.profitSituation"
                   @click-input="clickItem(data.profitSituation, '盈利情况', false)"
                   v-model="data.profitSituation === 'null'?'':data.profitSituation" label-width="6.5em"
                   class="lable"></van-field>
        <van-field v-if="data.others" label="其他" placeholder="人员等方面"
                   @click-input="clickItem(data.others, '其他', false)"
                   v-model="data.others === 'null'?'':data.others" label-width="6.5em" class="lable"></van-field>

        <div v-if="listCf.length>0">
          <van-row>
            <van-col span="16">
              <van-field label="船舶运营情况" :readonly="true" label-width="6.5em"></van-field>
            </van-col>
          </van-row>
          <van-field required v-for="(item,index) in listCf" :label="item.shipName" placeholder="请填写船舶运营情况"
                     :rules="customerForm.shipOperation"
                     @click-input="clickItem(item.shipOperation, item.shipName, false)"
                     v-model="item.shipOperation === 'null'?'':item.shipOperation" label-width="6.5em" class="lable"></van-field>
        </div>


        <van-field readonly type="textarea" label="遗留问题：" placeholder="遗留问题" :rules="customerForm.problerms"
                   v-model="data.problerms === 'null'?'':data.problerms"></van-field>
        <van-button v-if="!isHidden" type="primary" size="large" native-type="submit">保存
        </van-button>
      </van-form>
      <dialog-card :data="DialogData" :fieldValue="fieldValue" :show="show" @success="success" @editVal="editVal"></dialog-card>
    </div>
    <!-- 百度地图位置选择 -->
    <BMapAddressSelect :addressDetailInfo="this.addressDetailInfo" ref="bmapAddressSelect"
                       @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>

    <crm-tab-bar :tabpage="2"/>
  </div>
</template>

<script>
import BMapAddressSelect from "@/components/BMapAddressSelect/index";
import CrmTabBar from "@/components/CrmTabBar";
import DialogCard from "../../../components/DigitalCell/DialogCard";

export default {
  name: "BulkCrmsVisitTemporaryExistEdit",
  components: {CrmTabBar, BMapAddressSelect, DialogCard},
  data() {
    return {
      show: false,
      fieldValue: '',
      DialogData: {},
      edit: true,
      showEnterpriseNaturePicker: false,
      columnsEnterpriseNature: [
        {
          value: '货方',
          text: '货方'
        }, {
          value: '船方',
          text: '船方'
        }, {
          value: '港方',
          text: '港方'
        }, {
          value: '其他',
          text: '其他'
        }
      ],
      showVisitTypePicker: false,
      columnsVisitType: [
        {
          value: '',
          text: ''
        },
        {
          value: '001',
          text: '走访拜访'
        }, {
          value: '002',
          text: '电话回访'
        }, {
          value: '004',
          text: '客户来访'
        },
      ],
      isHidden: true,
      showPicker: false,
      maxDate: new Date(2030, 1, 1),
      currentDate: new Date(),
      data: [],
      options: [],
      value: [],
      list: [],
      listCf: [],
      loading: false,
      ifRequired: false,
      customerForm: {
        customerName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        cusType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        enterpriseNature: [
          {required: true, message: '', trigger: 'blur'},
        ],
        visitType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        target: [
          {required: true, message: '', trigger: 'blur'},
        ],
        presentSituation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        plan: [
          {required: true, message: '', trigger: 'blur'},
        ],
        getReady: [
          {required: true, message: '', trigger: 'blur'},
        ],
        realContact: [
          {required: true, message: '', trigger: 'blur'},
        ],
        pushResult: [
          {required: this.ifRequired, message: '', trigger: 'blur'},
        ],
        comOperate: [
          {required: !this.ifRequired, message: '', trigger: 'blur'},
        ],
        realOperate: [
          {required: true, message: '', trigger: 'blur'},
        ],
        otherDemand: [
          {required: true, message: '', trigger: 'blur'},
        ],
        interviewType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        goalPlan: [
          {required: true, message: '', trigger: 'blur'},
        ],
        shipOperation: [
          {required: true, message: '', trigger: 'blur'},
        ],
      },
      addressDetailInfo: {
        addressDetailed: '',
        addressLongitude: '',
        addressLatitude: '',
        addressProvince: '',
        addressCity: '',
      },
      interviewType: [
        '业务类拜访', '综合经营情况拜访'
      ],
      showInterviewTypePicker: false,
    }
  },
  watch: {
    'ifRequired': function () {
      this.customerForm.pushResult[0].required = this.ifRequired
      this.customerForm.comOperate[0].required = !this.ifRequired
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = []
      this.$ajax.post('/api/ajax/visitTemporary/getBulkCrmReplayDetail.json',
          {
            replayId: this.$route.params.id,
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          this.data = res.data[0]
          this.addressDetailInfo.addressDetailed = res.data[0].addressDetailed
          this.addressDetailInfo.addressLongitude = res.data[0].addressLongitude
          this.addressDetailInfo.addressLatitude = res.data[0].addressLatitude
          this.addressDetailInfo.addressProvince = res.data[0].addressProvince
          this.addressDetailInfo.addressCity = res.data[0].addressCity
          if(res.data[0].bulkCrmCfDetailDTO.length>0){
            this.listCf = res.data[0].bulkCrmCfDetailDTO
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
      console.log(this.list)
    },
    to(to) {
      this.$router.push(to)
    },
    onCancel() {

    },
    addPlan(formName) {
      setTimeout(() => {
        this.$router.push({path: '/BulkCrmsTravelPlanDetail'})
      }, 200);
    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    onConfirm(time) {
      this.data.date = this.formatter(time);
      this.showPicker = false;
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    handleSelectName(item) {
      this.data.title = item.value
    },
    /** 显示地图 */
    showMap() {
      this.$refs.bmapAddressSelect.show();
    },
    /** 确认地图地址 */
    confirmMapAddress(addressDetailInfo) {
      this.addressDetailInfo = addressDetailInfo;
    },
    showPopup() {
      if (this.data.addressOtherid.length > 0) {
        this.$router.push({path: '/BulkCrmsCusAddressDetailList/' + this.data.addressOtherid})
      } else {
        this.$message.info('暂无更多地址！');
      }
    },
    onClickEnterpriseNature() {
      this.showEnterpriseNaturePicker = false
    },
    onConfirmEnterpriseNature(picker, value) {
      this.data.enterpriseNature = picker.text
      this.showEnterpriseNaturePicker = false
    },
    onClickVisitType() {
      this.showVisitTypePicker = false
    },
    onConfirmVisitType(picker, value) {
      this.data.enterpriseType = picker.text
      this.showVisitTypePicker = false
    },
    onClickType() {
      this.showInterviewTypePicker = true
      // this.data.interviewType = ''
    },
    onConfirmType(picker, value, index) {
      console.log(picker, value, index);
      // this.data.interviewType = picker;
      this.showInterviewTypePicker = false;
    },
    clickItem(val, text, bool){
      this.DialogData.text = text
      this.DialogData.value = val
      this.DialogData.edit = bool
      this.fieldValue = val
      this.show = true
    },
    success(res){
      this.show = res
    },
    editVal(val){
      if(val.text == '业务推进成果'){
        this.data.pushResult = val.value
      }else if(val.text == '综合经营情况'){
        this.data.comOperate = val.value
      }else if(val.text == '业务量'){
        this.data.businessVolume = val.value
      }else if(val.text == '盈利情况'){
        this.data.profitSituation = val.value
      }else if(val.text == '其他'){
        this.data.others = val.value
      }
    }
  }
}
</script>

<style scoped>

.visit-temporary-exist-edit{
  background-color: #FFFFFF;
}

.visit-temporary-exist-edit .lable{
  margin-left: 15px;
  font-size: 12px;
}

.visit-temporary-exist-edit .title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.visit-temporary-exist-edit .data {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.visit-temporary-exist-edit {
  overflow-y: scroll;
  height: 2500px;
}

.visit-temporary-exist-edit .card {
  background: #318EF5;
}

</style>